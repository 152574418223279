import React, { useRef } from 'react'
import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import SvgCheckedCircle from '@svg/checkedCircle.inline'

import * as cx from './Packages.module.scss'

const PackageItem = React.forwardRef(({ item }, ref) => {
  return (
    <li className={cx.itemContainer}>
      <div className={cx.item}>
        <div className={cx.imageContainer}>
          <ElementImage src={item.img} alt={'Package Device Image'} />
        </div>
        <div className={cx.contentContainer}>
          <div className={cx.content}>
            <h1 className={item.headingClassName}>{item.heading}</h1>
            <h3 className={item.subheadingClassName}>{item.subheading}</h3>
            <div className>
              {item.descList?.map((desc, index) => {
                return (
                  <div key={index} className={cx.desc}>
                    <div>
                      <SvgCheckedCircle className={cx.checkSvg} />
                    </div>
                    <div>
                      <p className={`${cx.itemText} ${item.textClassName}`}>
                        {desc}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              className={`${cx.button} primary-button`}
              onClick={(e) => ref.current.openModal()}
            >
              learn more
            </button>
          </div>
        </div>
      </div>
    </li>
  )
})

const Packages = ({ content }) => {
  const modalFunc = useRef()

  return (
    <section id="packages" className={cx.wrapper}>
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today <br/>For A Free Consultation"
        mobileTitle="Sign Up Today For A Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <ul className={`${cx.container} container`} id="packages-list">
        {content?.map((item, index) => {
          return <PackageItem ref={modalFunc} key={index} item={item} />
        })}
      </ul>
    </section>
  )
}

export default Packages
