// extracted by mini-css-extract-plugin
export var button = "BlogContent-module--button--fe88a";
export var checkSvg = "BlogContent-module--check-svg--b854f";
export var container = "BlogContent-module--container--f043d";
export var contentContainer = "BlogContent-module--content-container--0f3a4";
export var desc = "BlogContent-module--desc--31b4b";
export var descList = "BlogContent-module--desc-list--9b545";
export var image = "BlogContent-module--image--79556";
export var imageContainer = "BlogContent-module--image-container--7ab08";
export var item = "BlogContent-module--item--3ae01";
export var itemText = "BlogContent-module--item-text--ed66f";
export var wrapper = "BlogContent-module--wrapper--96124";