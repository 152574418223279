import React, { useRef } from 'react'
import parse from 'html-react-parser'
import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import SvgCheckedCircle from '@svg/checkedCircle.inline'

import useMedia, { media } from '@hooks/useMedia'
import * as cx from './BlogContent.module.scss'

/* gradient-slider-200 */

const BlogContent = ({
  headingClassName,
  subheadingClassName,
  descClassName,
  content,
  image,
}) => {
  const modalFunc = useRef()
  const isTablet = useMedia(media.tablet)

  return (
    <section id="blog-content" className={cx.wrapper}>
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today <br/>For A Free Consultation"
        mobileTitle="Sign Up Today For A Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <div className={`${cx.container} container`}>
        <div className={cx.item}>
          <div className={cx.contentContainer}>
            <h1 className={headingClassName}>{parse(content.heading)}</h1>
            <h3 className={subheadingClassName}>{content.subheading}</h3>
            <div className={cx.descList}>
              {content.descList?.map((item, index) => {
                return (
                  <div key={index} className={cx.desc}>
                    <div>
                      <SvgCheckedCircle className={cx.checkSvg} />
                    </div>
                    <div>
                      <p className={`${cx.itemText} ${descClassName}`}>
                        {item}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              className={`${cx.button} primary-button large`}
              onClick={(e) => modalFunc.current.openModal()}
            >
              unlock hassle-free blogging
            </button>
          </div>
          <div className={cx.imageContainer}>
            <ElementImage
              src={image}
              alt={'Blog Content Image'}
              className={cx.image}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogContent
