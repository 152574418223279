import React from 'react'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import TabContent from '@components/pages/real-estate-blogging/TabContent'
import Preamble from '@components/pages/real-estate-blogging/Preamble'
import Packages from '@components/pages/real-estate-blogging/Packages'
import BlogContent from '@components/pages/real-estate-blogging/BlogContent'
import Services from '@components/pages/real-estate-blogging/Services'

import Seo from '@components/seo/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import { useContactFormMetadata } from '@hooks/contact-form-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import { getImage } from 'gatsby-plugin-image'

import {
  pageData as page,
  bannerData,
  tabsData,
  preambleData,
  packageData as packages,
  blogContentData,
  servicesData,
} from '@src/data/REDigitalMarketing'
import useREDigitalMarketingMetadata from '@hooks/re-digital-marketing-metadata'

import * as cx from './REDigitalMarketing.module.scss'

const REDigitalMarketing = () => {
  const {
    bannerBG,
    bannerImg,
    tabsBG,
    package1,
    package2,
    package3,
    blogContentImg,
    servicesImg,
  } = useREDigitalMarketingMetadata()
  const query = useContactFormMetadata()

  const packageData = [
    {
      heading: packages[0].heading,
      headingClassName: packages[0].headingClassName,
      subheading: packages[0].subheading,
      subheadingClassName: packages[0].subheadingClassName,
      descList: packages[0].descList,
      textClassName: packages[0].textClassName,
      img: extractImage(package1),
    },
    {
      heading: packages[1].heading,
      headingClassName: packages[1].headingClassName,
      subheading: packages[1].subheading,
      subheadingClassName: packages[1].subheadingClassName,
      descList: packages[1].descList,
      textClassName: packages[1].textClassName,
      img: extractImage(package2),
    },
    {
      heading: packages[2].heading,
      headingClassName: packages[2].headingClassName,
      subheading: packages[2].subheading,
      subheadingClassName: packages[2].subheadingClassName,
      descList: packages[2].descList,
      textClassName: packages[2].textClassName,
      img: extractImage(package3),
    },
  ]

  return (
    <LayoutInnerPage buttonsHeight={page.buttonsHeight}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout={bannerData.layout.container}
        bgImage={extractImage(bannerBG)}
        breadcrumbs={page.breadcrumbs}
        bannerImage={extractImage(bannerImg)}
        title={bannerData.heading.text}
        titleClassName={bannerData.heading.className}
        subtitle2={bannerData.subheading.text}
        subtitle2ClassName={bannerData.subheading.className}
        description={bannerData.paragraph.text}
        descriptionClassName={bannerData.paragraph.className}
        buttonScrollTo={bannerData.button.buttonScrollTo}
        button_text="See Our<br/> Blogging Packages"
        button_link="/"
      />
      <Tabs
        tabsClassName={tabsData.tabsClassName}
        activeTab={tabsData.activeTab}
      />
      <TabContent
        bgImage={extractImage(tabsBG)}
        heading={tabsData.heading}
        headingClassName={tabsData.headingClassName}
        subheading={tabsData.subheading}
        subheadingClassName={tabsData.subheadingClassName}
        description={tabsData.description}
        descriptionClassName={tabsData.descriptionClassName}
      />
      <Preamble
        title={preambleData.title}
        titleClassName={preambleData.titleClassName}
        description1={preambleData.desc1}
        description1ClassName={preambleData.desc1ClassName}
        description2={preambleData.desc2}
        description2ClassName={preambleData.desc2ClassName}
      />
      <Packages content={packageData} />
      <BlogContent
        content={blogContentData}
        headingClassName={blogContentData.headingClassName}
        subheadingClassName={blogContentData.subheadingClassName}
        descClassName={blogContentData.descClassName}
        image={extractImage(blogContentImg)}
      />
      <Services
        content={servicesData}
        headingClassName={servicesData.headingClassName}
        subheadingClassName={servicesData.subheadingClassName}
        image={extractImage(servicesImg)}
      />
      <LeadContactForm
        version="LCF03"
        background={null}
        modelOrBackground="Kirstin"
        title="Free Consultation"
        subtitle="Get In Touch With Us For A"
        submitLabel="Confirm My Appointment"
        leadName="Lead Contact Form"
        layout="default"
      />
    </LayoutInnerPage>
  )
}

export default REDigitalMarketing
