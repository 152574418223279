import React from 'react'

import * as cx from './Preamble.module.scss'

const Preamble = ({
  title,
  titleClassName,
  description1,
  description1ClassName,
  description2,
  description2ClassName,
}) => {
  return (
    <section id="preamble" className={cx.wrapper}>
      <div className={`${cx.container} container`}>
        <h1 className={titleClassName}>{title}</h1>
        <p className={description1ClassName}>{description1}</p>
        <p className={description2ClassName} id={'packages-list-scroll-ref'}>
          {description2}
        </p>
      </div>
    </section>
  )
}

export default Preamble
