// extracted by mini-css-extract-plugin
export var button = "Packages-module--button--ed67e";
export var checkSvg = "Packages-module--check-svg--75ccc";
export var container = "Packages-module--container--04b7e";
export var content = "Packages-module--content--01b39";
export var contentContainer = "Packages-module--content-container--8eef0";
export var desc = "Packages-module--desc--a2fc2";
export var imageContainer = "Packages-module--image-container--fe9e2";
export var item = "Packages-module--item--e5fed";
export var itemContainer = "Packages-module--item-container--7a1d2";
export var itemText = "Packages-module--item-text--2eb56";
export var wrapper = "Packages-module--wrapper--10a33";