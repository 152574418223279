export const pageData = {
  title: 'Real Estate Blogging',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Internet Marketing',
      url: '/real-estate-internet-marketing/',
    },
    {
      text: 'Real Estate Blogging',
      url: '/real-estate-internet-marketing/real-estate-blogging/',
    },
  ],
  uri: '/real-estate-internet-marketing/real-estate-blogging/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  layout: { container: 'product-intro' },
  heading: {
    text: 'Real Estate Blogging',
    className: 'heading-1',
  },
  subheading: {
    text:
      'WE OFFER DONE-FOR-YOU BLOGGING THAT BOOSTS YOUR TRAFFIC & REPUTATION',
    className: 'subtitle-7',
  },
  paragraph: {
    text:
      'Make your website the go-to source for trusted real estate insights with our professional blogging service.',
    className: 'default-body',
  },
  button: {
    buttonScrollTo: '#packages-list-scroll-ref',
  },
}

export const tabsData = {
  tabs: [
    'SEO Packages',
    'PPC Packages',
    'Blogging Packages',
    'Content Development',
    'Social Media Packages',
    'Custom Solutions',
  ],
  activeTab: 3,
  tabsClassName: 'small-heading',
  heading: 'Real Estate Blogging Packages',
  headingClassName: 'heading-2',
  subheading:
    "REGULARLY PUBLISHING HIGH-QUALITY BLOGS IS ONE OF THE MOST EFFECTIVE AND COST-EFFICIENT WAYS TO GENERATE MORE ORGANIC TRAFFIC. AND WHO WOULDN'T WANT THAT?",
  subheadingClassName: 'small-heading strong',
  description:
    'But if you can’t find time to write blogs with your busy schedule, Agent Image can help!<br/> Our real estate writers will study your brand inside out to create content that’s 100% unique and 100% you.',
  descriptionClassName: 'subtitle-5',
}

export const preambleData = {
  title: 'Words That Work',
  titleClassName: 'heading-3',
  desc1:
    'Our professionally written blogs are search engine optimized (SEO) to make your website easier to find online, making them the ideal complement to your pay-per-click campaigns.',
  desc1ClassName: 'subtitle-5',
  desc2:
    'We offer recurring blog packages that ensure your website always has fresh content that keeps people coming back.',
  desc2ClassName: 'subtitle-5',
}

export const packageData = [
  {
    heading: 'Blogging Plus',
    headingClassName: 'heading-3',
    subheading: 'Get your blog off to a solid start with our starter package.',
    subheadingClassName: 'subtitle-5',
    descList: [
      '3 articles a month.',
      '1 “page” is defined as an online article or blog post with a maximum of 500 words.',
      'Includes creation of meta title and description.',
      'Includes addition of social media sharing buttons on blog site.',
    ],
    textClassName: 'subtitle-5',
  },
  {
    heading: 'Blogging Pro',
    headingClassName: 'heading-3',
    subheading:
      'Give your prospects more of what they really want: well-written and informative real estate content.',
    subheadingClassName: 'subtitle-5',
    descList: [
      '6 articles a month.',
      '1 “page” is defined as an online article or blog post with a maximum of 500 words.',
      'Includes creation of meta title and description.',
      'Includes addition of social media sharing buttons on blog site.',
    ],
    textClassName: 'subtitle-5',
  },
  {
    heading: 'Blogging Premium',
    headingClassName: 'heading-3',
    subheading:
      'Maximize your search visibility by frequently publishing informative and engaging blogs.',
    subheadingClassName: 'subtitle-5',
    descList: [
      '12 articles a month.',
      '1 “page” is defined as an online article or blog post with a maximum of 500 words.',
      'Includes creation of meta title and description.',
      'Includes addition of social media sharing buttons on blog site.',
    ],
    textClassName: 'subtitle-5',
  },
]

export const blogContentData = {
  heading: 'The Easiest Way<br/> To Publish Quality<br/> Real Estate Content',
  headingClassName: 'heading-3',
  subheading: 'Expect no less than end-to-end services when you work with us:',
  subheadingClassName: 'small-heading strong',
  descList: [
    'Competitor Scan',
    'Keyword Research',
    'Copywriting',
    'Copyediting',
    'Publishing on Your Website',
  ],
  descClassName: 'default-body large',
}

export const servicesData = {
  heading: 'Not ready for a full blogging package yet?',
  headingClassName: 'heading-5',
  subheading:
    'No worries!  We also offer per-blog writing services.<br/> It’s the perfect way to see if we’re a good fit for you.',
  subheadingClassName: 'subtitle-5',
}
