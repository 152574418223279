import { useStaticQuery, graphql } from 'gatsby'

const useREDigitalMarketingMetadata = () => {
  const query = useStaticQuery(graphql`
    query reDigitalMarketingMetadata {
      bannerBG: file(relativePath: { eq: "blogging/bannerBG.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      bannerImg: file(relativePath: { eq: "blogging/bannerImage.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      tabsBG: file(relativePath: { eq: "blogging/tabsBG.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      package1: file(relativePath: { eq: "blogging/package1.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      package2: file(relativePath: { eq: "blogging/package2.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      package3: file(relativePath: { eq: "blogging/package3.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
      blogContentImg: file(
        relativePath: { eq: "blogging/blogcontent-img.png" }
      ) {
        id
        ...REDigitalMarketingImageOptimize
      }
      servicesImg: file(relativePath: { eq: "blogging/services-device.png" }) {
        id
        ...REDigitalMarketingImageOptimize
      }
    }

    fragment REDigitalMarketingImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useREDigitalMarketingMetadata
