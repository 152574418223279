import React from 'react'
import parse from 'html-react-parser'

import * as cx from './TabContent.module.scss'

const Tabs = ({
  bgImage,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  description,
  descriptionClassName,
}) => {
  return (
    <section
      id="tabs-content"
      className={cx.wrapper}
      style={{ backgroundImage: `url(${bgImage ? bgImage : 'none'})` }}
    >
      <div className={`${cx.container} container`}>
        <div className={cx.contentContainer}>
          <h1 className={headingClassName}>{heading}</h1>
          <hr />
          <h3 className={subheadingClassName}>{subheading}</h3>
          <p className={descriptionClassName}>{parse(description)}</p>
        </div>
      </div>
    </section>
  )
}

export default Tabs
